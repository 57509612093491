@use "_mantine" as mantine;
@use '../../../styles/variables';

.chatModal {
  .messages {
    overflow-y: scroll;
    margin-bottom: 6px;
    height: 300px;

    // Phone
    @media (max-width: variables.$phone-max-width) {
      height: 200px;
    }

    .message {
      .messageSender {
        margin-right: 6px;
        font-weight: bold;
      }
    }

    .messageError {
      color: var(--mantine-color-error);
    }
  }

  .input {
    position: relative;

    .loader {
      position: absolute;
      top: 8px;
      right: 6px;

      .loaderIcon {
        color: var(--mantine-color-dimmed);
      }
    }

    .inputMessage.sending {
      color: var(--mantine-color-dimmed);
    }
  }
}

.bubble {
  background-color: #eee;
}
