@use "_mantine" as mantine;
@use '../../../styles/variables';

.tabContainer {
  display: flex;
  flex-direction: row;

  .tabNav {
    display: flex;
    flex-direction: column;
    width: 170px;

    .tabItem {
      padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
      border-radius: var(--mantine-radius-sm);
      cursor: pointer;
      white-space: nowrap;

      &.active {
        background-color: #eee;
      }

      .tabIcon {
        margin-right: var(--mantine-spacing-sm);
        width: 16px;
      }

      // Phone
      @media (max-width: variables.$phone-max-width) {
        .tabIcon {
          margin-right: 0;
        }

        .tabLabel {
          display: none;
        }
      }
    }
  }

  .tabContent {
    padding: 0 var(--mantine-spacing-sm);
  }
}
