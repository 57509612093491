@use "_mantine" as mantine;
/*
@layer tailwind {
  // Must be within the tailwind layer or Mantine component styling breaks
  // https://github.com/orgs/mantinedev/discussions/1672
  @tailwind base;
}
*/
@use './variables';
@tailwind components;
@tailwind utilities;
@import './normalize.css';
@import '@mantine/core/styles.css';
@import '@mantine/carousel/styles.css';
@import '@mantine/dates/styles.css';
@import '@mantine/notifications/styles.css';
@import '@mantine/nprogress/styles.css';
@import '~@fortawesome/fontawesome-svg-core/styles.css';
@import '~ol/ol.css';

.page-container {
  margin-top: 45px;

  h1 {
    font-size: 3rem;
  }

  // Phone
  @media (max-width: variables.$phone-max-width) {
    margin-top: 8px;

    h1 {
      font-size: 2rem;
    }
  }
}

.content-block {
  margin-bottom: 16px;
}

.embedded-html {
  h1,
  h2,
  h3,
  h4 {
    margin-top: 8px;
    font-weight: bold;
  }

  h1 { font-size: 1.3rem; }
  h2 { font-size: 1.2rem; }
  h3 { font-size: 1.1rem; }
  h4 { font-size: 1rem; }
}

// Map: Attribution
.ol-attribution.ol-logo-only,
.ol-attribution.ol-uncollapsible {
  max-width: calc(100% - 3em) !important;
  height: 1.5em !important;
}

// Map: Controls
.ol-control button,
.ol-attribution,
.ol-scale-line-inner {
  font-family: var(--mantine-font-family) !important;
}

// Slots
.slot-status-available {
  background-color: #3dbb3d;
}

.slot-status-lottery {
  a:link, a:visited {
    color: #eadddd;
  }
  background-image:
    repeating-linear-gradient(
      45deg,
      #fc8a02,
      #fc8a02 10px,
      #ffba68 10px,
      #ffba68 20px
    );
}

.slot-status-unreleased {
  a:link, a:visited {
    color: #eadddd;
  }
  background-color: #fc8a02;
}

.slot-status-reserved {
  a:link, a:visited {
    color: #eadddd;
  }
  background-color: #f05454;
}

.slot-status-not-reservable {
  a:link, a:visited {
    color: #eadddd;
  }
  background-color: #8558d9;
}

.slot-status-unknown,
.slot-status-unavailable {
  background-color: #a1a1a1;
}

.slot-status-closed {
  background-image:
    repeating-linear-gradient(
      45deg,
      #a1a1a1,
      #a1a1a1 10px,
      #6e6e6e 10px,
      #6e6e6e 20px
    );
}