@use "_mantine" as mantine;
.watchCredits {
  background-color: #f6f6f6;
  padding: 32px;
  border-radius: 16px;

  .remaining {
    margin-bottom: 16px;

    .alreadyUsed {
      font-weight: 700;
    }

    .overage {
      color: var(--mantine-color-error);
    }
  }
}
